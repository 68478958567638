.header {
    height: 100px;
    background-color: #000000bb;
    width: 100%;
    padding: 10px 30px;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.logoAndLinks {
    display: flex;
    gap: 80px;
}

.buttons {
    display: flex;
    justify-content: end;
    align-items: end;
    margin-bottom: 21px;
    gap: 30px;
}

.contacts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 14px 0;
}

.icon {
    margin-right: 10px;
}

.tel {
    white-space: nowrap;
    font-size: 20px;
    font-family: var(--text-font);
    color: white;

}

@media (max-width: 1208px) {
    .buttons {
        display: none;
    }
}

@media (max-width: 570px) {
    .contacts {
        display: none;
    }
}