.main {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    overflow: hidden;
}

.about {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-right: 100px;
}

@media (max-width: 720px) {
    .about {
        padding-right: 0;
    }
}
