.certBox {
    display: flex;
    gap: 40px;
    width: 100%;
    flex-flow: wrap;
}

.certContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cert {
    width: 300px;
}

.download {
    cursor: pointer;
    text-decoration: underline;
}

.download:hover {
    color: var(--orange-color);
} 