.titleBox {
    display: flex;
    gap: 5px;
}

.title {
    font-family: var(--title-font);
    color: var(--orange-color);
    user-select: none;
}

.line {
    width: 8px;
    margin-top: 0.20em;
    margin-bottom: 0.1em;
    background-color: var(--orange-color);
}