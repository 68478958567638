* {padding: 0;margin: 0;border: 0;}
*, *:before, *:after {-webkit-box-sizing: border-box;box-sizing: border-box;}
:focus, :active {outline: none;}
a:focus, a:active {outline: none;}
nav, footer, header, aside {display: block;}
html, body {height: 100%;width: 100%;font-size: 100%;line-height: 1;font-size: 14px;-ms-text-size-adjust: 100%;-moz-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;}
input, button, textarea {font-family: inherit;}
input::-ms-clear {display: none;}
button {cursor: pointer;}
button::-moz-focus-inner {padding: 0;border: 0;}
a, a:visited {text-decoration: none; color: inherit;}
a:hover {text-decoration: none;}
ul li {list-style: none;}
img {vertical-align: top;}
h1, h2, h3, h4, h5, h6 {font-size: inherit;font-weight: 400;}

* {
  font-family: Arial, Helvetica, sans-serif;
}

html{
  background-color: black;
  scroll-behavior:smooth
}

.App {
  height: 100vh;
}

.App-Body {
  padding-top: 100px;
}

.App-Header {
  top: 0;
  position: fixed;
}

@font-face {
  font-family: "Exo2";
  font-style: normal;
  src: url('../public/fonts/Exo_2/Exo2-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-stretch: 1% 500%; /* Required by Chrome */
}

@font-face {
  font-family: "Play";
  font-style: normal;
  src: url('../public/fonts/Play/Play-Regular.ttf') format('truetype');
  font-weight: normal;
  font-stretch: 1% 500%; /* Required by Chrome */
}

@font-face {
  font-family: "Play";
  font-style: normal;
  src: url('../public/fonts/Play/Play-Bold.ttf') format('truetype');
  font-weight: bold;
  font-stretch: 1% 500%; /* Required by Chrome */
}

@font-face {
  font-family: "AlumniSansPinstripe";
  font-style: normal;
  src: url('../public/fonts/Alumni_Sans_Pinstripe/AlumniSansPinstripe-Regular.ttf') format('truetype');
  font-weight: normal;
  font-stretch: 1% 500%; /* Required by Chrome */
}

:root {
  --title-font: Exo2;
  --title-light-font: AlumniSansPinstripe;
  --text-font: Play;
}

.font-stretch {
  -webkit-transform: scale(1,.75);
  -moz-transform: scale(1,.75);
  -ms-transform: scale(1,.75);
  -o-transform: scale(1,.75);
  transform: scale(1,.75);
}

:root {
  --gray-bg-main-color: #60646D;
  --gray-on-bg-color: #FFFFFF30;
  --gray-on-bg-active-color: #FFFFFF80;
  --blue-main-color: #131f56;
  --orange-color: #e3791c;
  --orange-color-active: #f69139;
  --light-gray-color: #e6e6e6;
  --light-gray-active-color: #dedede;
  --bg-opacity: opacity(3%);
}