.text {
    display: inline;
    font-family: var(--text-font);
    user-select: text;
    color: white;
}

.lightText {
    display: block;
    font-family: var(--title-light-font);
    user-select: text;

    -webkit-transform: scale(1,.75);
    -moz-transform: scale(1,.75);
    -ms-transform: scale(1,.75);
    -o-transform: scale(1,.75);
    transform: scale(1,.75);
}