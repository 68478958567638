.headerButton {
    font-family: var(--title-font);
    color: rgb(240 240 240);
    font-size: 24px;
    cursor: pointer;
    white-space: nowrap;
}

.headerButton:hover {
    color: white;
}