.title {
    color: var(--orange-color);
    user-select: none;

    display: flex;

    justify-content: center;
    flex-direction: column;

    height: 70px;
}

.title:target{
    scroll-margin-top: 100px;
}