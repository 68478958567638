.titleBox {
    position: relative;
    height: calc(100vh - 100px - 60px);
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.secondTitle {
    font-size: 3vw;
    /* margin-bottom: 30px; */
}

.secondTitle > p {
    font-family: var(--text-font);
    color: white;
    user-select: none;
    /* text-align: end; */
}

.mainTitle {
    margin-bottom: 60px;

}

.mainTitle > p {
    font-size: 5vw;
    font-family: var(--title-font);
    color: white;
    user-select: none;
}

.gear {
    position: absolute;
    height: 60vw;
    bottom: -20vw;
    right: -30vw;
    filter: invert(1);
    opacity: 0.2;
}

@media (max-width: 720px) {
    .gear {
        display: none;
    }

    .mainTitle > p  {
        font-size: 9vw;
    }

    .secondTitle {
        font-size: 6vw;
    }
}